import type { MetaFunction } from '@remix-run/react';
import {
  Link,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import { fetchStrapiNoLimit } from '~/lib/server/api.server';
import Layout from '~/components/layout/Layout';
import { Blocks } from '~/components/blocks/BlockManager';
import type { Page as PageType } from '@cardo/types';
import { IoArrowBackSharp } from 'react-icons/io5';
import { generateMetaFromSeoComponent } from '~/lib/meta';
import { generateImgProps, getAlt } from '~/lib/strapiImage';
import NotFoundErrorBoundary from '~/components/error/NotFoundErrorBoundary';
import type { LoaderFunctionArgs } from '@remix-run/node';
import { DefaultErrorBoundary } from '@cardo/ui';

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return <NotFoundErrorBoundary />;
  }

  return <DefaultErrorBoundary />;
}

export async function loader({ params }: LoaderFunctionArgs) {
  const pathname = params['*'];

  if (!pathname) {
    throw new Response('Not Found', { status: 404 });
  }

  const pathParts = pathname?.split('/');

  let slug;

  if (pathParts.length === 1) slug = pathParts[0];
  if (pathParts.length > 1) slug = pathParts[pathParts.length - 1];

  const res = await fetchStrapiNoLimit<PageType[]>('/pages', {
    populate: 'deep',
    filters: {
      slug,
    },
  });

  const pages = res.data;

  if (!pages || pages.length === 0) {
    throw new Response('Not Found', { status: 404 });
  }

  const pageData = pages[0];

  if (pageData.attributes.content) {
    try {
      pageData.attributes.content = JSON.parse(pageData.attributes.content);
    } catch (_err) {
      throw new Response("Couldn't parse content into JSON", { status: 500 });
    }
  }

  return pageData;
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    ...generateMetaFromSeoComponent(data?.attributes.seo),
    { title: 'Cardonomics' },
  ];
};

export default function Page() {
  const pageData = useLoaderData<typeof loader>();
  const { title, featuredImage, blocks } = pageData.attributes;

  return (
    <Layout heroContainerClassName="h-8 sm:h-16">
      <div className="max-w-screen h-full w-full overflow-auto">
        <div className="sm:max-w-4/5 mx-auto flex flex-col gap-6">
          <h1>{title}</h1>
          <Link to=".." className="flex w-fit items-center">
            <IoArrowBackSharp className="text-lg" />
            <span className="ml-0.5 text-sm font-semibold">Back</span>
          </Link>
          {featuredImage.data && (
            <img
              {...generateImgProps(featuredImage)}
              alt={getAlt(featuredImage)}
              className="max-w-screen object-cover"
            />
          )}
          {blocks && (
            <div className="flex flex-col gap-8">
              <Blocks blocks={blocks} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
